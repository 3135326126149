<template>
  <!-- commerce -->
  <div>
    <div class="white-bg">
      <div class="create-org-wrap" :class="{ 'dn': !showNewCommerce }">
        <div class="add-org">
          <div class="title">
            Добавить <br />
            организацию
          </div>
          <form>
            <input type="text" placeholder="Имя" />
            <input type="text" placeholder="Фамилия" />
            <input type="text" placeholder="Телефон" />
            <input type="text" placeholder="Номер лицевого счета" />

            <div class="btn-row">
              <button type="button" class="back-btn" @click="toggleAddOrg">Назад</button>
              <button type="button" class="next-btn" @click="addOrg">Далее</button>
            </div>
          </form>
        </div>
      </div>
      <div v-for="block in blocks" :key="`block-${block.id}`" :class="{ 'dn': showNewCommerce }">
        <div class="block-title">Блок {{ block.number }}</div>
        <div class="org-row">
<!--          <div class="user-item user-info">-->
<!--            <div class="text">-->
<!--              <div class="name">Магазин “Тест”</div>-->
<!--              <div class="user-status">Продуктовый магазин</div>-->
<!--            </div>-->
<!--            <div class="debt">-->
<!--              <div class="text">Долг</div>-->
<!--              <div class="sum">0.000</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="add-org-btn" @click="toggleAddOrg">+ Добавить организацию</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBlocksApi } from '@/api/companies';
import { mapState } from 'vuex';

export default {
  data: () => ({
    showEditEntrance: false,
    showAddOrg: false,
    blocks: [],
  }),
  computed: {
    ...mapState(['loading']),
    showNewCommerce() {
      return this.blocks.length === 0 && !this.loading.some((l) => l === 'companies.getBlocksApi') || this.showAddOrg;
    },
  },
  methods: {
    toggleEditEntrance() {
      this.showEditEntrance = !this.showEditEntrance;
    },
    toggleDeleteEntrance() {
      this.showDeleteEntrance = !this.showDeleteEntrance;
      this.showEditEntrance = !this.showEditEntrance;
    },
    toggleAddOrg() {
      this.showAddOrg = !this.showAddOrg;
    },
    async getBlocks() {
      const res = await getBlocksApi();
      this.blocks = res.data;
    },
    init() {
      this.getBlocks();
    },
    addOrg() {
      console.log('add org');
    },
  },
  created() {
    this.init();
  },
}
</script>

<style>

</style>
